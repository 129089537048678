import { useState } from "react";
import { Box, Grid, Typography } from "@mui/material";
import { IOfferItems } from "../interface/IOffer";

interface Props {
  data: IOfferItems;
}

export default function OfferCard({ data }: Props) {
  const [isHovering, setIsHovering] = useState(false);

  return (
    <Grid item xs={12} md={4} my={3}>
      {/* PC */}
      <Box
        sx={{
          display: { xs: "none", md: "block" },
          transition: "all 0.3s ease-in-out",
          cursor: "pointer",
          borderRadius: 7,
          height: 280,
          bgcolor: isHovering ? "primary.main" : "transparent",
          py: 4,
          px: 3,
          "&:hover": {
            boxShadow: 9,
          },
        }}
        onMouseEnter={() => setIsHovering(true)}
        onMouseLeave={() => setIsHovering(false)}
      >
        <Box
          sx={{
            position: "relative",
            display: "flex",
            justifyContent: "center",
            alignItems: "center",
            bgcolor: isHovering ? "common.white" : "primary.main",
            width: 85,
            height: 85,
            margin: "auto",
            borderRadius: 10,
            boxShadow: isHovering
              ? "1px 1px 50px 10px rgba(255,255,255,0.77);"
              : "1px 1px 50px 10px rgba(44,134,235,0.77);",
          }}
        >
          <Box
            sx={{
              "& svg": {
                color: isHovering ? "primary.main" : "common.white",
              },
            }}
          >
            {data.icon}
          </Box>
        </Box>
        <Typography
          variant="h6"
          fontWeight={"bold"}
          mt={4}
          textAlign={"center"}
          color={isHovering ? "common.white" : "secondary.main"}
        >
          {data.title}
        </Typography>
        <Typography
          variant="body1"
          textAlign={"center"}
          color={"common.black"}
          sx={{
            maxWidth: 300,
            margin: "auto",
            mt: 2,
            display: isHovering ? "none" : "block",
          }}
        >
          {data.description}
        </Typography>
        <Typography
          variant="h6"
          fontWeight={"bold"}
          textAlign={"center"}
          sx={{
            display: isHovering ? "block" : "none",
            color: "white",
            mt: 3,
          }}
        >
          {data.price}
        </Typography>
      </Box>
      {/* MOBILE */}
      <Box
        sx={{
          display: { xs: "block", md: "none" },
          transition: "all 0.3s ease-in-out",
          cursor: "pointer",
          borderRadius: 7,
          height: 280,
          bgcolor: isHovering ? "primary.main" : "transparent",
          p: 3,
          boxShadow: isHovering ? 9 : 0,
        }}
        onClick={() => setIsHovering(!isHovering)}
      >
        <Box
          sx={{
            position: "relative",
            display: "flex",
            justifyContent: "center",
            alignItems: "center",
            bgcolor: isHovering ? "common.white" : "primary.main",
            width: 85,
            height: 85,
            margin: "auto",
            borderRadius: 10,

            boxShadow: isHovering
              ? "1px 1px 50px 10px rgba(255,255,255,0.77);"
              : "1px 1px 50px 10px rgba(44,134,235,0.77);",
          }}
        >
          <Box
            sx={{
              "& svg": {
                color: isHovering ? "primary.main" : "common.white",
              },
            }}
          >
            {data.icon}
          </Box>
        </Box>
        <Typography
          variant="h6"
          fontWeight={"bold"}
          mt={4}
          textAlign={"center"}
          color={isHovering ? "common.white" : "secondary.main"}
        >
          {data.title}
        </Typography>
        <Typography
          variant="h6"
          fontWeight={"bold"}
          textAlign={"center"}
          sx={{
            color: isHovering ? "common.white" : "common.black",
            mt: 2,
          }}
        >
          {data.price}
        </Typography>
        <Typography
          variant="body1"
          textAlign={"center"}
          color={"common.black"}
          sx={{
            maxWidth: 300,
            margin: "auto",
            color: isHovering ? "common.white" : "common.black",
            mt: 2,
          }}
        >
          {data.description}
        </Typography>
      </Box>
    </Grid>
  );
}
