import React, { useState } from "react";

import { Avatar, TextField, Typography } from "@mui/material";
import Box from "@mui/material/Box";
import { IComment } from "../../interface/ICustomerProject";
import moment from "moment";

import PersonIcon from "@mui/icons-material/Person";
import SupportAgentIcon from "@mui/icons-material/SupportAgent";
import { useAutoAnimate } from "@formkit/auto-animate/react";

interface IProps {
  comments: IComment[] | undefined;
}

export default function ProjectModalChat({ comments }: IProps) {
  const [collapseChat, setCollapseChat] = useState<boolean>(false);
  const [useRefAnim] = useAutoAnimate<HTMLDivElement>({ duration: 500 });
  return (
    <Box
      sx={{
        width: "100%",
        height: "fit-content",
        boxShadow: 10,
        borderRadius: 3,
        transition: "all 0.5s ease",
      }}
    >
      <Box
        sx={{
          height: 50,
          borderTopLeftRadius: 7,
          borderTopRightRadius: 7,
          display: "flex",
          alignItems: "center",
          justifyContent: "space-between",
          px: 3,
          width: "100%",
        }}
      >
        <Typography
          variant="body2"
          fontWeight="bold"
          color="common.black"
          fontSize={"1.3rem"}
        >
          Chat
        </Typography>
        <Box
          sx={{
            display: "flex",
            alignItems: "center",
            justifyContent: "flex-end",
            cursor: "pointer",
          }}
          onClick={() => setCollapseChat(!collapseChat)}
        >
          <Box
            sx={{
              width: 15,
              height: 15,
              bgcolor: collapseChat ? "limegreen" : "orange",
              borderRadius: 50,
            }}
          />
        </Box>
      </Box>
      <Box
        ref={useRefAnim}
        sx={{
          p: 2,
          height: "100%",
          maxHeight: 400,
          display: collapseChat ? "none" : "block",
          overflowY: "auto",
          bgcolor: "#EBEEF1",
          transition: "all 0.5s ease",
        }}
      >
        {comments?.map((comment, index) => (
          <React.Fragment key={index}>
            {comment.fromUs ? (
              <Box
                sx={{
                  display: "flex",
                  justifyContent: "flex-end",
                }}
              >
                <Box
                  sx={{
                    width: "fit-content",
                    maxWidth: 600,
                    my: 2,
                  }}
                >
                  <Box>
                    <Typography
                      sx={{
                        textAlign: "right",
                        mr: "50px",
                        color: "common.black",
                      }}
                    >
                      {comment.from.split(" ")[0]}
                    </Typography>
                  </Box>
                  <Box sx={{ display: "flex" }}>
                    <Box
                      sx={{
                        width: "calc(100% - 30px)",
                        boxShadow: 2,
                        p: 2,
                        borderRadius: 2,
                        flex: 1,
                        bgcolor: "common.white",
                      }}
                    >
                      <Typography
                        variant="body2"
                        fontWeight="bold"
                        color="common.black"
                      >
                        {comment.message}
                      </Typography>
                    </Box>
                    <Box sx={{ width: 50, ml: 2, mr: -2 }}>
                      <Avatar sx={{ bgcolor: "secondary.main" }}>
                        <SupportAgentIcon />
                      </Avatar>
                    </Box>
                  </Box>
                  <Box>
                    <Typography
                      variant="body2"
                      fontWeight="bold"
                      color="common.black"
                      sx={{ mt: 1, textAlign: "right", mr: "50px" }}
                    >
                      {moment(comment.date).fromNow()}
                    </Typography>
                  </Box>
                </Box>
              </Box>
            ) : (
              <Box
                sx={{
                  width: "fit-content",
                  maxWidth: 600,
                  my: 2,
                }}
              >
                <Box sx={{ display: "flex" }}>
                  <Box sx={{ width: 50 }}>
                    <Avatar sx={{ bgcolor: "primary.main" }}>
                      <PersonIcon />
                    </Avatar>
                  </Box>
                  <Box
                    sx={{
                      width: "calc(100% - 30px)",
                      boxShadow: 2,
                      p: 2,
                      borderRadius: 2,
                      flex: 1,
                      bgcolor: "common.white",
                    }}
                  >
                    <Typography
                      variant="body2"
                      fontWeight="bold"
                      color={"common.black"}
                    >
                      {comment.message}
                    </Typography>
                  </Box>
                </Box>
                <Box>
                  <Typography
                    variant="body2"
                    fontWeight="bold"
                    color="common.black"
                    sx={{ mt: 1, marginLeft: "50px" }}
                  >
                    {moment(comment.date).fromNow()}
                  </Typography>
                </Box>
              </Box>
            )}
          </React.Fragment>
        ))}
      </Box>
      <Box
        sx={{
          bgcolor: "common.white",
          borderBottomLeftRadius: 10,
          borderBottomRightRadius: 10,
          width: "calc(100% + 2px)",
          position: "relative",
          left: -1,
          display: collapseChat ? "none" : "block",
        }}
      >
        <TextField
          fullWidth
          placeholder={"Zpráva"}
          color={"secondary"}
          sx={{
            color: "common.black",
            "& fieldset": {
              borderRadius: 0,
              borderBottomLeftRadius: 10,
              borderBottomRightRadius: 10,
              color: "common.black",
            },
          }}
        />
      </Box>
    </Box>
  );
}
