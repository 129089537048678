import { useState, useEffect } from "react";
import AppBar from "@mui/material/AppBar";
import Typography from "@mui/material/Typography";
import Button from "@mui/material/Button";
import MenuIcon from "@mui/icons-material/Menu";
import Box from "@mui/material/Box";
import { Grid, Paper } from "@mui/material";
import { Home } from "@mui/icons-material";
import { useNavigate } from "react-router-dom";

import CodeRoundedIcon from "@mui/icons-material/CodeRounded";
import AccountTreeRoundedIcon from "@mui/icons-material/AccountTreeRounded";
import GroupRoundedIcon from "@mui/icons-material/GroupRounded";
import CallRoundedIcon from "@mui/icons-material/CallRounded";
import CloseIcon from "@mui/icons-material/Close";
import HelpOutlineIcon from "@mui/icons-material/HelpOutline";
import { useAutoAnimate } from "@formkit/auto-animate/react";

import FullLogo from "../images/fulllogo.png";

interface INavLink {
  name: string;
  path: string;
  icon: JSX.Element;
  variant?: any;
  color?: string;
}

export default function Navbar() {
  const navigate = useNavigate();
  const [showMobileMenu, setShowMobileMenu] = useState(false);
  const [animationParent] = useAutoAnimate();
  const [animationMenu] = useAutoAnimate();
  const handleShowModileMenuChange = () => setShowMobileMenu(!showMobileMenu);
  const [activeTab, setActiveTab] = useState<string>("home");

  const [links] = useState<INavLink[]>([
    { name: "Domů", path: "/#home", icon: <Home /> },
    { name: "Služby", path: "/#offers", icon: <CodeRoundedIcon /> },
    { name: "Projekty", path: "/#projects", icon: <AccountTreeRoundedIcon /> },
    { name: "Reference", path: "/#references", icon: <GroupRoundedIcon /> },
    { name: "FAQ", path: "/#faq", icon: <HelpOutlineIcon /> },
    {
      name: "Kontakt",
      path: "/#contact",
      icon: <CallRoundedIcon />,
      variant: "contained",
      color: "white",
    },
  ]);

  useEffect(() => {
    const handleScroll = () => {
      links.forEach((link: INavLink) => {
        const element = document.querySelector(link.path.replace("/", ""));
        if (element) {
          const offset = element.getBoundingClientRect().top;
          if (offset <= 90) {
            setActiveTab(link.path.replace("/", ""));
          }
        }
      });
    };
    window.addEventListener("scroll", handleScroll);
    return () => {
      window.removeEventListener("scroll", handleScroll);
    };
  }, [links]);

  const linkStyles = (link: INavLink) => {
    if (activeTab === link.path.replace("/", "")) {
      return {
        mx: 3,
        color: link.variant ? "common.white" : "primary.main",
        transition: "all 0.3s ease-in-out",
        height: { xs: link.variant ? 50 : 60, md: link.variant ? 40 : 66 },
        borderColor: "primary.main",
        borderBottom: link.variant ? "none" : "3px solid",
        borderTop: link.variant ? "none" : "3px solid",
        borderRadius: link.variant ? 2 : 1,
        fontWeight: "bold",
      };
    } else {
      return {
        mx: 3,
        color: link.variant ? "common.white" : "common.black",
        height: { xs: link.variant ? 50 : 60, md: link.variant ? 40 : 66 },
        borderRadius: link.variant ? 2 : 1,
        transition: "all 0.3s ease-in-out",
        borderTop: link.variant ? "none" : "3px solid",
        borderBottom: link.variant ? "none" : "3px solid",
        borderColor: "transparent",
        fontWeight: "bold",
        "&:hover": {
          borderColor: "common.black",
        },
      };
    }
  };

  return (
    <>
      <AppBar position="fixed" color={"transparent"} sx={{ boxShadow: "none" }}>
        {/* DESKTOP */}
        <Paper
          elevation={10}
          sx={{
            display: { xs: "none", md: "flex" },
            backdropFilter: "blur(15px)",
            width: "70%",
            minWidth: 950,
            mx: "auto",
            mt: 3,
            borderRadius: 4,
            px: 3,
            height: 60,
            bgcolor: "rgba(255,255,255,0.65)",
            alignItems: "center",
          }}
        >
          <Grid container>
            <Grid item xs={2}>
              <Box
                sx={{
                  display: "flex",
                  alignItems: "center",
                  height: "100%",
                  ml: -2,
                }}
              >
                <img src={FullLogo} width={120} alt={"logo"} />
              </Box>
            </Grid>
            <Grid
              item
              xs={10}
              sx={{ display: "flex", justifyContent: "flex-end" }}
              ref={animationParent}
            >
              {links.map((item, index: number) => (
                <Box
                  key={index}
                  sx={{
                    display: "flex",
                    justifyContent: "center",
                    alignItems: "center",
                  }}
                >
                  <Button
                    sx={linkStyles(item)}
                    startIcon={item.icon}
                    onClick={() => navigate(item.path)}
                    variant={item.variant ? item.variant : "text"}
                  >
                    {item.name}
                  </Button>
                </Box>
              ))}
            </Grid>
          </Grid>
        </Paper>
        {/* MOBILE */}
        <Paper
          elevation={10}
          sx={{
            display: { xs: "flex", md: "none" },
            backdropFilter: "blur(10px)",
            width: "90%",
            mx: "auto",
            mt: 3,
            borderRadius: 4,
            px: 3,
            height: "auto",
            py: 2,
            bgcolor: "rgba(255,255,255,0.7)",
            alignItems: "center",
            transition: "all 0.3s ease-in-out",
          }}
        >
          <Grid container>
            <Grid item xs={6} display={"flex"} alignItems={"center"}>
              <Typography
                variant={"h6"}
                color={"secondary.main"}
                fontWeight={"bold"}
              >
                DISCOPE
              </Typography>
            </Grid>
            <Grid
              item
              xs={6}
              display={"flex"}
              justifyContent={"flex-end"}
              alignItems={"center"}
            >
              <Button
                onClick={() => handleShowModileMenuChange()}
                ref={animationMenu}
              >
                {showMobileMenu ? (
                  <CloseIcon
                    sx={{ fontSize: "1.8rem", color: "secondary.main" }}
                  />
                ) : (
                  <MenuIcon
                    sx={{
                      fontSize: "1.8rem",
                      color: "secondary.main",
                    }}
                  />
                )}
              </Button>
            </Grid>
            <Grid
              item
              mt={3}
              xs={12}
              sx={{
                transition: "all 0.3s ease-in-out",
                display: showMobileMenu ? "block" : "none",
              }}
            >
              {links.map((item: INavLink, index: number) => (
                <Box display={"flex"} alignContent={"center"} key={index}>
                  <Button
                    sx={{
                      ...linkStyles(item),
                      justifyContent: item.variant ? "center" : "flex-start",
                      mt: { xs: item.variant ? 2 : 0 },
                    }}
                    startIcon={item.icon}
                    variant={item.variant ? item.variant : "text"}
                    fullWidth
                    onClick={() => {
                      navigate(item.path);
                      handleShowModileMenuChange();
                    }}
                  >
                    {item.name}
                  </Button>
                </Box>
              ))}
            </Grid>
          </Grid>
        </Paper>
      </AppBar>
    </>
  );
}
