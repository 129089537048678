import DescriptionIcon from "@mui/icons-material/Description";
import FolderIcon from "@mui/icons-material/Folder";
import ImageIcon from "@mui/icons-material/Image";
import FolderZipIcon from "@mui/icons-material/FolderZip";

export default function useCustomerProjects() {
  const iconStyles = {
    fontSize: "1.8rem",
    color: "common.black",
    mr: 2,
  };

  const fileIcons = {
    folder: <FolderIcon sx={{ ...iconStyles, color: "#81C7F7" }} />,
    textFile: <DescriptionIcon sx={{ ...iconStyles, color: "#5AADFE" }} />,
    image: <ImageIcon sx={{ ...iconStyles, color: "#6B6FF0" }} />,
    zip: <FolderZipIcon sx={{ ...iconStyles, color: "#A7C1D4" }} />,
  };
  return [
    {
      uuid: "c2ac3d48-ae5e-4ce0-8e14-48089d67a51e",
      name: "Autoškola Vetner",
      description:
        "Webové stránky a webová aplikace pro autoškolu. Moderní design a jednoduché ovládání. Černá a červená barva. Možnost administrace z mobilu.",
      status: 2,
      type: ["Webové stránky", " Webová aplikace"],

      requirements: [
        {
          label: "kalendář jízd",
          done: true,
        },
        {
          label: "možnost přihlásit se jako žák",
          done: true,
        },
        {
          label: "zobrazit účet žáka s informacemi o jízdách",
          done: true,
        },
        {
          label: "zkušební testy na závěrečné jízdy",
          done: true,
        },
        {
          label: "blog s články",
          done: true,
        },
        {
          label: "zobrazit detail závěrečné zkoušky",
          done: true,
        },
        {
          label: "zprávy",
          done: false,
        },
        {
          label: "notifikace o změnách",
          done: false,
        },
      ],
      estimatedPrice: 19000,
      estimatedHostingPrice: 299,
      projectAccepted: "2023-01-09",
      estimatedDelivery: "2023-02-11",
      files: [
        {
          name: "Faktura",
          icon: fileIcons.textFile,
          path: "https://faktura-pdf.cz/img/faktura-pdf.png",
          lastUpdated: "2023-01-13",
          size: "84 KB",
          file_count: "1 soubor",
        },
        {
          name: "Dokumentace",
          icon: fileIcons.textFile,
          path: "",
          lastUpdated: "2023-01-13",
          size: "2.5 MB",
          file_count: "1 soubor",
        },
        {
          name: "Design",
          icon: fileIcons.folder,
          path: "",
          lastUpdated: "2023-01-13",
          size: "13.7 MB",
          file_count: "4 soubory",
          folder_content: [
            {
              name: "Design - v1",
              icon: fileIcons.zip,
              path: "",
              lastUpdated: "2023-01-10",
              size: "2.1 MB",
              file_count: "1 soubor",
            },
            {
              name: "Design - v2",
              icon: fileIcons.zip,
              path: "",
              lastUpdated: "2023-01-10",
              size: "2.6 MB",
              file_count: "1 soubor",
            },
            {
              name: "Design - v3",
              icon: fileIcons.zip,
              path: "",
              lastUpdated: "2023-01-13",
              size: "2.2 MB",
              file_count: "1 soubor",
            },
            {
              name: "Design - v3 - extended",
              icon: fileIcons.zip,
              path: "",
              lastUpdated: "2023-01-13",
              size: "2.9 MB",
              file_count: "1 soubor",
            },
          ],
        },
        {
          name: "Zdrojové kódy",
          icon: fileIcons.folder,
          path: "",
          lastUpdated: "2023-02-08",
          size: "263.8 MB",
          file_count: "2 soubory",
          folder_content: [
            {
              name: "Frontend",
              icon: fileIcons.zip,
              path: "",
              lastUpdated: "2023-02-08",
              size: "263.8 MB",
              file_count: "342 soubory",
            },
            {
              name: "Backend",
              icon: fileIcons.zip,
              path: "",
              lastUpdated: "2023-02-08",
              size: "321.8 MB",
              file_count: "274 soubory",
            },
          ],
        },
        {
          name: "Loga",
          icon: fileIcons.folder,
          path: "",
          lastUpdated: "2023-01-13",
          size: "1.9 MB",
          file_count: "3 soubory",
          folder_content: [
            {
              name: "Logo - v1",
              icon: fileIcons.zip,
              path: "",
              lastUpdated: "2023-01-10",
              size: "2 MB",
              file_count: "11 souborů",
            },
            {
              name: "Logo - v2",
              icon: fileIcons.zip,
              path: "",
              lastUpdated: "2023-01-11",
              size: "3.5 MB",
              file_count: "13 souborů",
            },
            {
              name: "Logo - v3",
              icon: fileIcons.zip,
              path: "",
              lastUpdated: "2023-01-12",
              size: "3.9 MB",
              file_count: "13 souborů",
            },
          ],
        },
        {
          name: "Akce - screenshot",
          icon: fileIcons.image,
          path: "",
          lastUpdated: "2023-01-15",
          size: "1 MB",
          file_count: "1 soubor",
        },
      ],
      testingDomain: "https://www.dev1.discope/autoskola-vetner.cz",
      domain: "https://www.autoskola-vetner.cz",
      comments: [
        {
          from: "Martin Vetner",
          date: "2023-01-13",
          message:
            "Potřeboval bych ještě přidat okénko s akcí a vlastní úpravy kdy a co zobrazovat za akce.",
          fromUs: false,
        },
        {
          from: "Daniel Seiner",
          date: "2023-01-13",
          message:
            "Dobře, v souboru DESIGN naleznete úpravy, okénko se bude zobrazovat jen pro nové náštěvniky. Popřípadě vše je napsané v dokumentaci. Přečtěte si prosím sekci 3.4.2.1, zde je popsáno, jak se akce budou zobrazovat a jaké možnosti úprav budou k dispozici.",
          fromUs: true,
        },
        {
          from: "Martin Vetner",
          date: "2023-01-14",
          message: "Super, díky moc.",
          fromUs: false,
        },
        {
          from: "Daniel Seiner",
          date: "2023-01-15",
          message: "Přidáno, změny si můžete prohlédnout na testovací doméně.",
          fromUs: true,
        },
        {
          from: "Daniel Seiner",
          date: "2023-01-18",
          message:
            "Přidáno na produkční verzi. V případě nejastnosti nebo dalších úprav mi napiště :)",
          fromUs: true,
        },
        {
          from: "Martin Vetner",
          date: "2023-01-20",
          message: "Vše funguje, díky!",
          fromUs: false,
        },
      ],
    },
  ];
}
