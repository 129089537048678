import React, { useState, useEffect } from "react";
import {
  Box,
  Button,
  Checkbox,
  Container,
  FormControlLabel,
  FormGroup,
  Grid,
  Menu,
  Modal,
  Typography,
} from "@mui/material";
import { useAutoAnimate } from "@formkit/auto-animate/react";

import useProjects from "../../../../hooks/useProjects";

import IProject from "../../../../interface/IProject";
import IFilter from "../../../../interface/IFilter";

import FilterAltIcon from "@mui/icons-material/FilterAlt";
import FilterAltOffIcon from "@mui/icons-material/FilterAltOff";
import ProjectFinder from "../../../../components/Project/ProjectFinder";

export default function Projects() {
  const data = useProjects();
  const [useRefAnim] = useAutoAnimate<HTMLDivElement>({ duration: 500 });
  const [filteredData, setFilteredData] = useState<IProject[]>(data);
  const [isSticky, setIsSticky] = useState<boolean>(false);
  const [showModal, setShowModal] = useState<boolean>(false);
  const [modalData, setModalData] = useState<IProject | null>(null);
  const handleShowModalChange = () => setShowModal(!showModal);
  const showModalData = (data: IProject) => {
    setModalData(data);
    handleShowModalChange();
  };
  const [menuAnchorEl, setMenuAnchorEl] = useState<null | HTMLElement>(null);
  const handleOpenMenu = (event: React.MouseEvent<HTMLButtonElement>) => {
    setMenuAnchorEl(event.currentTarget);
  };
  const handleCloseMenu = () => {
    setMenuAnchorEl(null);
  };
  const filterMenuOpen = Boolean(menuAnchorEl);
  const [filters, setFilters] = useState<IFilter[]>([
    {
      label: "Prezenční weby",
      value: true,
      filter_value: "static_web",
      filter_count: data.filter((d) => d.type.includes("static_web")).length,
    },
    {
      label: "Webové aplikace",
      value: true,
      filter_value: "web_app",
      filter_count: data.filter((d) => d.type.includes("web_app")).length,
    },
    {
      label: "E-shopy",
      value: true,
      filter_value: "eshop",
      filter_count: data.filter((d) => d.type.includes("eshop")).length,
    },
    {
      label: "Programy",
      value: true,
      filter_value: "desktop_app",
      filter_count: data.filter((d) => d.type.includes("desktop_app")).length,
    },
    {
      label: "Podnikové systémy",
      value: true,
      filter_value: "crm",
      filter_count: data.filter((d) => d.type.includes("crm")).length,
    },
    {
      label: "Mobilní aplikace",
      value: true,
      filter_value: "mobile_app",
      filter_count: data.filter((d) => d.type.includes("mobile_app")).length,
    },
  ]);
  const handleFilterChange = (event: any, filter: IFilter) => {
    const newFilters = filters.map((f) => {
      if (f.label === filter.label) {
        f.value = event.target.checked;
      }
      return f;
    });
    setFilters(newFilters);
  };

  const clearFilters = () => {
    const newFilters = filters.map((f) => {
      f.value = true;
      return f;
    });
    setFilters(newFilters);
  };

  useEffect(() => {
    const newFilteredData = data.filter((d) => {
      let isFiltered = false;
      filters.forEach((f) => {
        if (f.value && d.type.includes(f.filter_value)) {
          isFiltered = true;
        }
      });
      return isFiltered;
    });
    setFilteredData(newFilteredData);
  }, [filters]);

  useEffect(() => {
    const handleScroll = () => {
      const element = document.querySelector("#projects");
      if (element) {
        const offset = element.getBoundingClientRect().top;
        setIsSticky(offset <= 0);
      }
    };
    window.addEventListener("scroll", handleScroll);
    return () => {
      window.removeEventListener("scroll", handleScroll);
    };
  }, []);

  return (
    <>
      {/* MODAL*/}
      <Modal
        open={showModal}
        onClose={handleShowModalChange}
        aria-labelledby="modal-modal-title"
        aria-describedby="modal-modal-description"
      >
        <Box
          sx={{
            position: "absolute",
            top: "50%",
            left: "50%",
            transform: "translate(-50%, -50%)",
            width: "95%",
            height: "95vh",
            bgcolor: "background.paper",
            borderRadius: 7,
          }}
        >
          {modalData && modalData.url !== "" && (
            <>
              <iframe
                src={modalData.url}
                title={modalData.title}
                width={"100%"}
                height={"100%"}
                style={{ border: 0, borderRadius: 7, outline: "none" }}
              />
            </>
          )}
        </Box>
      </Modal>
      <Container id={"projects"}>
        <Box>
          <Grid container spacing={3}>
            <Grid item xs={12} md={4}>
              <Box className={`sticky-header move ${isSticky ? "sticky" : ""}`}>
                <Typography
                  variant="h4"
                  fontWeight={"bold"}
                  color={"secondary.main"}
                  textAlign={{ xs: "center" }}
                >
                  Naše projekty
                </Typography>
                <Typography
                  sx={{
                    color: "common.black",
                    width: { xs: "100%", md: "70%" },
                    mx: "auto",
                    mt: 2,
                  }}
                >
                  Zde můžete najít některé z našich nejúspěšnějších projektů v
                  oblasti softwarového vývoje. Jsme hrdí na naši schopnost
                  přizpůsobit se potřebám našich klientů a vytvořit řešení,
                  která pomáhají jejich podnikání.
                  <Typography
                    component={"span"}
                    sx={{ display: "block", mt: 3 }}
                  >
                    Pro zobrazení projektu klikněte na obrázek.
                  </Typography>
                </Typography>
              </Box>
            </Grid>
            <Grid item xs={12} md={8}>
              <Box sx={{ display: "flex", justifyContent: "flex-end", gap: 3 }}>
                <Button
                  variant={"contained"}
                  sx={{ bgcolor: "common.black" }}
                  onClick={clearFilters}
                >
                  <FilterAltOffIcon />
                </Button>
                <Button
                  variant={"contained"}
                  color={"secondary"}
                  onClick={handleOpenMenu}
                >
                  <FilterAltIcon />
                </Button>
              </Box>
              <Menu
                id="filter-menu"
                anchorEl={menuAnchorEl}
                open={filterMenuOpen}
                onClose={handleCloseMenu}
                MenuListProps={{
                  "aria-labelledby": "basic-button",
                }}
                PaperProps={{
                  elevation: 0,
                  sx: {
                    width: 250,
                    overflow: "visible",
                    filter: "drop-shadow(0px 2px 8px rgba(0,0,0,0.32))",
                    mt: 2,
                    px: 3,
                    "&:before": {
                      content: '""',
                      display: "block",
                      position: "absolute",
                      top: 0,
                      right: 14,
                      width: 10,
                      height: 10,
                      bgcolor: "background.paper",
                      transform: "translateY(-50%) rotate(45deg)",
                      zIndex: 0,
                    },
                  },
                }}
                transformOrigin={{ horizontal: "right", vertical: "top" }}
                anchorOrigin={{ horizontal: "right", vertical: "bottom" }}
              >
                <FormGroup>
                  {filters.map((item: IFilter, index: number) => (
                    <Grid container key={index}>
                      <Grid item xs={11}>
                        <FormControlLabel
                          sx={{ color: "common.black" }}
                          control={
                            <Checkbox
                              checked={item.value}
                              color={"secondary"}
                              onChange={(e) => handleFilterChange(e, item)}
                            />
                          }
                          label={item.label}
                        />
                      </Grid>
                      <Grid item xs={1}>
                        <Typography>({item.filter_count})</Typography>
                      </Grid>
                    </Grid>
                  ))}
                </FormGroup>
              </Menu>
              <Grid container spacing={3} ref={useRefAnim}>
                {filteredData.length === 0 && (
                  <Typography
                    variant="h6"
                    fontSize={"1.5rem"}
                    fontWeight={"bold"}
                    sx={{ color: "common.black", mx: "auto", pt: 5 }}
                  >
                    Bohužel jsme nenašli žádná data
                  </Typography>
                )}
                {filteredData.map((item: IProject, index: number) => (
                  <Grid item xs={12} md={6} key={index} my={3}>
                    <Box
                      sx={{
                        transition: "all 0.3s ease-in-out",
                        cursor: "pointer",
                        "&:hover": {
                          transform: "scale(1.06)",
                        },
                      }}
                      onClick={() =>
                        item.url !== "" ? showModalData(item) : null
                      }
                    >
                      <img
                        src={item.image}
                        alt="projekt"
                        style={{
                          width: "100%",
                          height: 235,
                          borderRadius: 7,
                        }}
                      />
                      <Typography
                        variant="h6"
                        fontWeight={"bold"}
                        mt={2}
                        color={"secondary.main"}
                      >
                        {item.title}
                      </Typography>
                      <Typography variant="body1" color={"common.black"} mt={2}>
                        {item.description}
                      </Typography>
                    </Box>
                  </Grid>
                ))}
              </Grid>
              <ProjectFinder />
            </Grid>
          </Grid>
        </Box>
      </Container>
    </>
  );
}
