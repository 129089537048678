import uniautoservis from "../images/projects/uniautoservis.png";
import Belveder from "../images/projects/belveder.png";
import Sento from "../images/projects/sento.png";
export default function useProjects() {
  return [
    {
      title: "Sento",
      description:
        "Webové stránky pro realitní kancelářs s administrací pro přidání a exportování inzerátů. Přehledný a responzivní design.",
      image: Sento,
      url: "",
      type: ["static_web", "web_app"],
    },
    {
      title: "Belveder",
      description:
        "Webové stránky pro realitní kancelář s moderním a zároveň jednoduchým designem.",
      image: Belveder,
      url: "",
      type: ["static_web", "web_app"],
    },
    {
      title: "Uniautoservis",
      description:
        "Webové stránky pro autoservis, které obsahují aplikaci pro evidenci aut a zaměstnanců.",
      image: uniautoservis,
      url: "https://uniautoservis.cz/",
      type: ["static_web", "desktop_app"],
    },
  ];
}
