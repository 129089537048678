import WebIcon from "@mui/icons-material/Web";
import MonitorIcon from "@mui/icons-material/Monitor";
import ShoppingCartIcon from "@mui/icons-material/ShoppingCart";
import SmartphoneIcon from "@mui/icons-material/Smartphone";
import CloudIcon from "@mui/icons-material/Cloud";
import DisplaySettingsIcon from "@mui/icons-material/DisplaySettings";

import AutoAwesomeMosaicIcon from "@mui/icons-material/AutoAwesomeMosaic";
import ImageIcon from "@mui/icons-material/Image";
import BackupIcon from "@mui/icons-material/Backup";
import AdminPanelSettingsIcon from "@mui/icons-material/AdminPanelSettings";
import SourceIcon from "@mui/icons-material/Source";
import TerminalIcon from "@mui/icons-material/Terminal";

import HomeRepairServiceIcon from "@mui/icons-material/HomeRepairService";
import PreviewIcon from "@mui/icons-material/Preview";
import CloudDoneIcon from "@mui/icons-material/CloudDone";

export default function useOffers() {
  const iconStyles = { color: "secondary.main", fontSize: "3rem" };
  const benefitsIconStyles = { color: "secondary.main", fontSize: "1.5rem" };
  return {
    offers: [
      {
        icon: <WebIcon sx={iconStyles} />,
        title: "Prezenční web",
        description: "Webové stránky s jednoduchou administrací.",
        price: "od 5 000 Kč",
      },
      {
        icon: <CloudIcon sx={iconStyles} />,
        title: "Webové aplikace",
        description: "Webová aplikace na míru s kompletní administrací.",
        price: "od 10 000 Kč",
      },
      {
        icon: <ShoppingCartIcon sx={iconStyles} />,
        title: "E-shop",
        description:
          "E-shop s kompletní administrací a přístupem z mobilního zařízení.",
        price: "od 10 000 Kč",
      },
      {
        icon: <MonitorIcon sx={iconStyles} />,
        title: "Dekstopové aplikace",
        description:
          "Programy na míru s možností připojení k aplikaci nebo databázi.",
        price: "od 13 000 Kč",
      },
      {
        icon: <MonitorIcon sx={iconStyles} />,
        title: "Informační systémy",
        description:
          "Desktopové programy na míru s možností propojení s webovou aplikací.",
        price: "od 13 000 Kč",
      },
      {
        icon: <DisplaySettingsIcon sx={iconStyles} />,
        title: "Podnikové systémy",
        description:
          "CRM systémy pro firmy jako  webové aplikace nebo desktopové aplikace.",
        price: "od 15 000 Kč",
      },
      {
        icon: <SmartphoneIcon sx={iconStyles} />,
        title: "Mobílní aplikace",
        description: "Mobilní aplikace pro operační systémy iOS a Android.",
        price: "od 15 000 Kč",
      },
      {
        icon: <PreviewIcon sx={iconStyles} />,
        title: "Grafický návrh",
        description:
          "Narvhneme Vám grafický návrh projektu dle vašich požadavků.",
        price: "od 5 000 Kč",
      },
      {
        icon: <TerminalIcon sx={iconStyles} />,
        title: "Skripty",
        description: "Vytvoříme Vám skript s libovolnou funkcionalitou.",
        price: "od 5 000 Kč",
      },
    ],
    services: [
      {
        icon: <HomeRepairServiceIcon sx={iconStyles} />,
        title: "Údržba projektu",
        description:
          "Postaráme se o pravidelnou údržbu vašich stránek či programu.",
        price: "od 3 000 Kč",
      },
      {
        icon: <PreviewIcon sx={iconStyles} />,
        title: "Domény",
        description: "Zajistíme Vám doménu za přijatelnou cenu.",
        price: "od 5 000 Kč",
      },
      {
        icon: <CloudDoneIcon sx={iconStyles} />,
        title: "Zprovoznění",
        description: "Zajistíme Vám prvotní spuštění projetku.",
        price: "od 5 000 Kč",
      },
    ],
    benefits: [
      {
        title: "Grafický návrh",
        icon: <AutoAwesomeMosaicIcon sx={benefitsIconStyles} />,
      },
      {
        title: "Logo",
        icon: <ImageIcon sx={benefitsIconStyles} />,
      },
      {
        title: "Denní zálohy ",
        icon: <BackupIcon sx={benefitsIconStyles} />,
      },
      {
        title: "Administraci",
        icon: <AdminPanelSettingsIcon sx={benefitsIconStyles} />,
      },
      {
        title: "Zdrojový kód",
        icon: <SourceIcon sx={benefitsIconStyles} />,
      },
    ],
  };
}
