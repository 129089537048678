import Box from "@mui/material/Box";
import Button from "@mui/material/Button";
import Paper from "@mui/material/Paper";
import Typography from "@mui/material/Typography";
import React from "react";
import { useNavigate } from "react-router-dom";

export default function ErrorHandler() {
  const navigate = useNavigate();
  return (
    <Box>
      <Box
        id={"home"}
        sx={{
          width: "100%",
          height: "70vh",
          bgcolor: "black",
          display: "flex",
          justifyContent: "center",
          alignItems: "center",
          flexDirection: "column",
          backgroundImage: "url(https://wallpapercave.com/wp/wp2691582.jpg)",
        }}
      >
        <Typography variant="h3" color="white" fontWeight={"bold"}>
          <Typography
            component={"span"}
            variant={"h3"}
            fontWeight={"bold"}
            color={"error"}
          >
            {"<"}
          </Typography>
          ERROR
          <Typography
            component={"span"}
            variant={"h3"}
            fontWeight={"bold"}
            color={"error"}
          >
            {" />"}
          </Typography>
        </Typography>
      </Box>
      {/* CONTENT */}
      <Paper
        elevation={10}
        sx={{
          width: "95%",
          margin: "auto",
          bgcolor: "rgba(255,255,255,0.85)",
          position: "relative",
          top: -80,
          borderRadius: 7,
          backdropFilter: "blur(10px)",
        }}
      >
        <Box sx={{ textAlign: "center" }}>
          <Typography
            sx={{
              color: "common.black",
              maxWidth: 500,
              textAlign: "center",
              margin: "auto",
              py: 3,
              fontSize: "1.3rem",
            }}
          >
            Omlouváme se, ale nastala chyba. Stránka, kterou se snažíte
            zobrazit, není k dispozici. Můžete se pokusit obnovit stránku nebo
            navštívit jinou stránku. Pokud problém přetrvává, kontaktujte naši
            podporu. Děkujeme Vám za pochopení.
          </Typography>
          <Button
            variant="contained"
            color={"secondary"}
            sx={{ my: 3, width: 150, fontWeight: "bold" }}
            onClick={() => navigate("/")}
          >
            Zpět
          </Button>
        </Box>
      </Paper>
    </Box>
  );
}
