import { useState } from "react";
import {
  Box,
  Button,
  ButtonGroup,
  Chip,
  Grid,
  Modal,
  Stack,
  Typography,
} from "@mui/material";

import ICustomerProject from "../../interface/ICustomerProject";
import { Check } from "@mui/icons-material";
import AutorenewIcon from "@mui/icons-material/Autorenew";
import moment from "moment";
import ProjectModalChat from "./ProjectModalChat";

import CalendarMonthIcon from "@mui/icons-material/CalendarMonth";
import BugReport from "@mui/icons-material/BugReport";
import EditIcon from "@mui/icons-material/Edit";
import CustomStepper from "../Stepper";
import ProjectFiles from "./ProjectFiles";

import ExpandMoreIcon from "@mui/icons-material/ExpandMore";
import ExpandLessIcon from "@mui/icons-material/ExpandLess";
interface IProps {
  showModal: boolean;
  closeModal: () => void;
  modalData: ICustomerProject | undefined;
}

interface IProperty {
  label: string;
  value: string | number | JSX.Element;
  link?: string;
}

export default function ProjectModal({
  showModal,
  closeModal,
  modalData,
}: IProps) {
  const [expandAll, setExpandAll] = useState<boolean>(false);

  const renderProperty = (property: IProperty) => {
    return (
      <Box sx={{ my: 3 }}>
        <Typography
          sx={{
            fontSize: "1rem",
            color: "common.black",
            fontWeight: "bold",
            textTransform: "uppercase",
          }}
        >
          {property.label}
        </Typography>
        {property.link ? (
          <Typography
            sx={{
              fontSize: "1rem",
              color: "common.black",
              cursor: "pointer",
              ml: 3,
            }}
            onClick={() => window.open(property.link, "_blank")}
          >
            {property.value}
          </Typography>
        ) : (
          <Typography sx={{ fontSize: "1rem", color: "secondary", ml: 3 }}>
            {property.value}
          </Typography>
        )}
      </Box>
    );
  };

  const formatPrice = (price: number) => {
    return price
      .toLocaleString("cs-CS", {
        style: "currency",
        currency: "CZK",
      })
      .replace(",00", "");
  };

  const generateButton = (color: string) => {
    return (
      <Box
        onClick={() => closeModal()}
        sx={{
          width: 15,
          height: 15,
          bgcolor: color,
          borderRadius: 50,
          display: "flex",
          justifyContent: "center",
          alignItems: "center",
          ml: 2,
          cursor: "pointer",
        }}
      />
    );
  };

  const handleExpandChange = (bool: boolean) => {
    setExpandAll(bool);
  };

  return (
    <Modal
      open={showModal}
      onClose={closeModal}
      aria-labelledby="modal-modal-title"
      aria-describedby="modal-modal-description"
    >
      <Box
        sx={{
          position: "absolute",
          top: "50%",
          left: "50%",
          transform: "translate(-50%, -50%)",
          width: "90%",
          maxWidth: 1000,
          minWidth: 300,
          bgcolor: "background.paper",
          boxShadow: 24,
          borderRadius: 4,
          outline: "none",
        }}
        style={{ maxHeight: "90vh" }}
      >
        <Box
          sx={{
            width: "100%",
            height: 40,
            bgcolor: "secondary.main",
            position: "fixed",
            zIndex: 2,
            top: -1,
            borderTopRightRadius: 15,
            borderTopLeftRadius: 15,
          }}
        >
          <Grid container sx={{ width: "100%", mx: "auto", height: 40 }}>
            <Grid
              item
              xs={3}
              display={"flex"}
              alignItems={"center"}
              height={40}
            >
              {generateButton("red")}
              {generateButton("orange")}
              {generateButton("limegreen")}
            </Grid>
            <Grid
              item
              xs={6}
              display={"flex"}
              alignItems={"center"}
              justifyContent={"center"}
            >
              <Typography
                variant="h6"
                sx={{
                  fontSize: "1rem",
                  color: "common.black",
                  fontWeight: "bold",
                  textAlign: "center",
                  maxWidth: { xs: 50, md: "100%" },
                  textOverflow: "ellipsis",
                  overflow: "hidden",
                  whiteSpace: "nowrap",
                }}
              >
                Projekt #{modalData?.uuid}
              </Typography>
            </Grid>
          </Grid>
        </Box>
        <Box
          sx={{ p: 3, overflowY: "auto", mt: 4 }}
          style={{ maxHeight: "75vh" }}
        >
          <Typography sx={{ fontSize: "2rem", color: "secondary" }}>
            {modalData?.name}
          </Typography>

          <Typography
            variant="h6"
            sx={{ fontSize: "1rem", color: "common.black" }}
          >
            {modalData?.description}
          </Typography>
          <Box>
            <CustomStepper value={modalData ? modalData.status : 0} />
          </Box>
          <Grid
            container
            sx={{ my: 4 }}
            spacing={3}
            flexDirection={"row-reverse"}
          >
            <Grid item xs={12} md={6}>
              <Box>
                <Typography
                  sx={{
                    fontSize: "1.3rem",
                    fontWeight: "bold",
                    color: "common.black",
                    mt: 3,
                    mb: 1,
                  }}
                >
                  Požadavky
                </Typography>
                <Box sx={{ ml: 3 }}>
                  {modalData?.requirements.map((item, index) => (
                    <Box
                      key={index}
                      sx={{
                        display: "flex",
                        alignItems: "center",
                        gap: 2,
                      }}
                    >
                      {item.done ? (
                        <Check sx={{ color: "primary.main" }} />
                      ) : (
                        <AutorenewIcon sx={{ color: "secondary.main" }} />
                      )}
                      <Typography
                        sx={{
                          fontSize: "1rem",
                          color: "common.black",
                          mt: 1,
                          mb: 1,
                        }}
                      >
                        {item.label}
                      </Typography>
                    </Box>
                  ))}
                </Box>
              </Box>
            </Grid>
            <Grid item xs={12} md={6} my={3}>
              <Box>
                <Stack direction="row" spacing={2} mb={4}>
                  {modalData?.type.map((item, index) => (
                    <Chip
                      key={index}
                      label={item}
                      sx={{ fontWeight: "bold", textTransform: "uppercase" }}
                      color={index % 2 === 0 ? "primary" : "secondary"}
                    />
                  ))}
                </Stack>
              </Box>
              {renderProperty({
                label: "Projektový manažer",
                value: `Daniel Seiner`,
              })}
              {renderProperty({
                label: "datum přijetí",
                value: moment(modalData?.projectAccepted).format("DD.MM.YYYY"),
              })}
              {renderProperty({
                label: "Předpokládaný datum odevzdání",
                value: moment(modalData?.estimatedDelivery).format(
                  "DD.MM.YYYY"
                ),
              })}
              {renderProperty({
                label: "Předpokládaná cena",
                value: `${
                  modalData?.estimatedPrice &&
                  formatPrice(modalData?.estimatedPrice)
                }`,
              })}
              {renderProperty({
                label: "Předpokládaná cena hostování",
                value: `${
                  modalData?.estimatedHostingPrice &&
                  formatPrice(modalData?.estimatedHostingPrice)
                } / měsíc`,
              })}
              {renderProperty({
                label: "Testovací verze",
                value: `${modalData?.testingDomain}`,
                link: modalData?.testingDomain,
              })}
              {renderProperty({
                label: "Produkční verze",
                value: `${modalData?.domain}`,
                link: modalData?.domain,
              })}
            </Grid>
          </Grid>

          <Box>
            <ProjectModalChat comments={modalData?.comments} />
          </Box>

          <Box sx={{ mt: 5, pb: 4 }}>
            <Grid container>
              <Grid item xs={8}>
                <Typography
                  sx={{
                    fontSize: "1.3rem",
                    fontWeight: "bold",
                    color: "common.black",
                  }}
                >
                  Soubory
                </Typography>
              </Grid>
              <Grid item xs={4} justifyContent={"flex-end"} display={"flex"}>
                <ButtonGroup variant="text" color={"secondary"}>
                  <Button variant={expandAll ? "contained" : "outlined"}>
                    <ExpandMoreIcon onClick={() => handleExpandChange(true)} />
                  </Button>
                  <Button variant={!expandAll ? "contained" : "outlined"}>
                    <ExpandLessIcon onClick={() => handleExpandChange(false)} />
                  </Button>
                </ButtonGroup>
              </Grid>
            </Grid>

            <ProjectFiles files={modalData?.files} expandAll={expandAll} />
          </Box>
        </Box>
        {/* FOOTER PC */}
        <Box
          sx={{
            p: 2,
            px: 3,
            boxShadow: 10,
            borderBottomRightRadius: 18,
            borderBottomLeftRadius: 18,
            display: "flex",
          }}
        >
          <Box sx={{ display: { xs: "none", md: "flex" }, gap: 2 }}>
            <ButtonGroup variant="text" color={"secondary"}>
              <Button color={"primary"}>Upravit požadavky</Button>
              <Button color={"secondary"}>Navrhnout schůzku</Button>
              <Button color={"error"}>Nahlásit chybu</Button>
            </ButtonGroup>
          </Box>
          <Box sx={{ display: { xs: "flex", md: "none" }, gap: 2 }}>
            <ButtonGroup variant="text" color={"secondary"}>
              <Button color={"primary"}>
                <EditIcon />
              </Button>
              <Button color={"secondary"}>
                <CalendarMonthIcon />
              </Button>
              <Button color={"error"}>
                <BugReport />
              </Button>
            </ButtonGroup>
          </Box>
        </Box>
      </Box>
    </Modal>
  );
}
