import { useAutoAnimate } from "@formkit/auto-animate/react";
import Box from "@mui/material/Box";
import Divider from "@mui/material/Divider";
import moment from "moment";
import React from "react";
import { IFile } from "../../../interface/ICustomerProject";

interface IProps {
  files: IFile[] | undefined;
  expandAll: boolean;
}

const tableHeadStyle = {
  fontWeight: "bold",
  // color: "common.black",
  color: "secondary.main",
  fontSize: "0.9rem",
  textAlign: "right",
};

const tableCellStyle = {
  fontSize: "1rem",
  textAlign: "right",
};

export default function ProjectFiles({ files, expandAll }: IProps) {
  return (
    <Box
      sx={{
        boxShadow: 20,
        overflowX: "auto",
        borderRadius: 4,
        mt: 3,
      }}
    >
      <Box
        sx={{
          overflowY: "scroll",
          width: 968,
        }}
        component={"div"}
      >
        <Box
          sx={{
            display: "flex",
            height: 50,
            alignItems: "center",
            p: 3,
          }}
        >
          <Box sx={{ width: 377, ...tableHeadStyle, textAlign: "left" }}>
            Název
          </Box>
          <Box sx={{ width: 197, ...tableHeadStyle, textAlign: "left" }}>
            Počet souborů
          </Box>
          <Box sx={{ width: 197, ...tableHeadStyle }}>Poslední úprava</Box>
          <Box sx={{ width: 197, ...tableHeadStyle, textAlign: "right" }}>
            Velikost
          </Box>
        </Box>
        <Divider />
        <Box sx={{ p: 2, maxHeight: 240, overflowY: "auto" }}>
          {files?.map((file: IFile, index: number) => (
            <TableRow file={file} key={index} expandAll={expandAll} />
          ))}
        </Box>
      </Box>
    </Box>
  );
}

interface IRowProps {
  file: IFile;
  expandAll: boolean;
}

const TableRow = ({ file, expandAll }: IRowProps) => {
  const [open, setOpen] = React.useState<boolean>(false);

  React.useEffect(() => {
    if (file.folder_content) {
      setOpen(expandAll);
    }
  }, [expandAll]);

  const handleOpenChange = () => {
    if (file.folder_content) {
      setOpen(!open);
    }
  };
  const [autoAnimRef] = useAutoAnimate();
  return (
    <Box ref={autoAnimRef}>
      <Box
        onClick={handleOpenChange}
        display={"flex"}
        sx={{
          px: 2,
          py: 3,
          alignItems: "center",
          borderRadius: 4,
          transition: "all 0.3s ease-in-out",
          cursor: "pointer",
          color: "common.black",
          "&:hover": {
            backgroundColor: "rgba(44,134,235, 0.2)",
          },
          "&:hover div": {
            color: "primary.main",
          },
        }}
      >
        <Box
          sx={{
            width: 377,
            ...tableCellStyle,
            fontWeight: "bold",
            color: "secondary.main",
            display: "flex",
            alignItems: "center",
            textAlign: "left",
          }}
        >
          {file.icon}
          {file.name}
        </Box>
        <Box
          sx={{
            width: 197,
            ...tableCellStyle,
            textAlign: "left",
          }}
        >
          {file.file_count}
        </Box>
        <Box sx={{ width: 197, ...tableCellStyle }}>
          {moment(file.lastUpdated).format("MMM DD, YYYY")}
        </Box>
        <Box
          sx={{
            width: 197,
            ...tableCellStyle,
          }}
        >
          {file.size}
        </Box>
      </Box>
      {open && (
        <>
          {file.folder_content?.map((sub_file: IFile, index: number) => (
            <Box sx={{ px: 3 }} key={index}>
              <Box
                display={"flex"}
                sx={{
                  py: 3,
                  px: 2,
                  my: 1,
                  alignItems: "center",
                  borderRadius: 4,
                  transition: "all 0.3s ease-in-out",
                  cursor: "pointer",
                  color: "common.black",
                  bgcolor: "rgba(44,134,235, 0.08)",
                  "&:hover": {
                    backgroundColor: "rgba(44,134,235, 0.2)",
                  },
                  "&:hover div": {
                    color: "primary.main",
                  },
                }}
              >
                <Box
                  sx={{
                    width: 377,
                    ...tableCellStyle,
                    fontWeight: "bold",
                    color: "secondary.main",
                    display: "flex",
                    alignItems: "center",
                    textAlign: "left",
                  }}
                >
                  {sub_file.icon}
                  {sub_file.name}
                </Box>
                <Box
                  sx={{
                    width: 197,
                    ...tableCellStyle,
                    textAlign: "left",
                  }}
                >
                  {sub_file.file_count}
                </Box>
                <Box sx={{ width: 197, ...tableCellStyle }}>
                  {moment(sub_file.lastUpdated).format("MMM DD, YYYY")}
                </Box>
                <Box
                  sx={{
                    width: 197,
                    ...tableCellStyle,
                  }}
                >
                  {sub_file.size}
                </Box>
              </Box>
            </Box>
          ))}
        </>
      )}
    </Box>
  );
};
