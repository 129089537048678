import { FacebookOutlined, Instagram } from "@mui/icons-material";
import { Box, Container, Grid, Typography } from "@mui/material";
import useFooter from "../hooks/useFooter";

export default function Footer() {
  const data = useFooter();
  return (
    <Container style={{ marginTop: 130, paddingBottom: 20 }}>
      <Grid container spacing={4}>
        <Grid item xs={12} md={6}>
          <Typography variant="h4" fontWeight={"bold"} color={"secondary.main"}>
            Discope
          </Typography>
          <Typography
            color={"common.black"}
            sx={{ width: { xs: "100%", md: "70%" } }}
          >
            Zaměřujeme se na kvalitu, bezpečnost a vyhovení požadavků klientů.
            Používáme nejnovější technologie a každý projekt u nás prochází
            několika fázemi. Na thru jsme více jak 2 roky a náš tým obsahuje
            specialisty ze všech oborů.
          </Typography>
          <Box
            sx={{
              display: "flex",
              alignItems: "center",
              mt: 2,
            }}
          >
            <FacebookOutlined
              sx={{ color: "secondary.main", fontSize: "2rem" }}
            />
            <Instagram
              sx={{ color: "secondary.main", ml: 2, fontSize: "2rem" }}
            />
          </Box>
        </Grid>
        {data.map((item: any, index: number) => (
          <Grid item xs={6} md={3} key={index}>
            <Typography
              variant="h6"
              fontWeight={"bold"}
              color={"secondary.main"}
              pb={2}
            >
              {item.title}
            </Typography>
            {item.sublinks.map((link: any, index2: number) => (
              <Typography variant="body1" color={"common.black"} key={index2}>
                {link.title}
              </Typography>
            ))}
          </Grid>
        ))}
      </Grid>
      <Box sx={{ textAlign: "center", mt: 5, color: "common.black" }}>
        Copyright © 2020-2022 Discope. Všechna práva vyhrazena.
      </Box>
    </Container>
  );
}
