import Home from "@mui/icons-material/Home";

export default function useStats() {
  return [
    {
      animation: "slide-left 1s ease-in-out",
      title: "Zhovotených projektů",
      value: 12,
      sufix: "+",
      icon: <Home />,
      desription: "Úspěšně jsme doručili více jak 12 projektů.",
    },
    {
      title: "Spojených zákazníků",
      value: 8,
      sufix: "+",
      icon: <Home />,
      desription: "Naše služby vyhověli mnoho zákazníkům.",
    },
    {
      animation: "slide-right 1s ease-in-out",
      title: "Projektů ve vývoji",
      value: 4,
      icon: <Home />,
      desription: "Náš tým se podílí na vývoji náročných zakázek.",
    },
  ];
}
