import React from "react";
import { useEffect } from "react";
import { Button, Grid, Typography } from "@mui/material";
import Box from "@mui/material/Box";
import Paper from "@mui/material/Paper";
import { useLocation } from "react-router-dom";
import { useParams } from "react-router-dom";
import moment from "moment";
import "moment/locale/cs";
import LOGO from "../../images/logo-nobg-s.png";

import ArrowForwardIosIcon from "@mui/icons-material/ArrowForwardIos";

// SECTIONS
import Offers from "./Sections/Offers";
import Projects from "./Sections/Projects";
import References from "./Sections/References";
import Contact from "./Sections/Contact";
import Stats from "./Sections/Stats";
import FAQ from "./Sections/FAQ";

moment.locale("cs");

export default function HomePage() {
  const { uuid } = useParams<{ uuid: string }>();
  const location = useLocation();

  useEffect(() => {
    if (location.hash) {
      handleScroll(location.hash.replace("#", ""));
    }
  }, [location]);

  useEffect(() => {
    if (uuid) {
      handleScroll("showProject");
    }
  }, [uuid]);

  const handleScroll = (e: string) => {
    const element = document.getElementById(e);
    if (element) {
      setTimeout(() => {
        element.scrollIntoView({ behavior: "smooth" });
      }, 200);
    }
  };

  return (
    <Box>
      {/* BACKGROUND IMAGE */}
      <Box
        id={"home"}
        sx={{
          width: "100%",
          height: "80vh",
          display: "flex",
          justifyContent: "center",
          alignItems: "center",
          flexDirection: "column",
          bgcolor: "#111314",
        }}
      >
        <Grid container spacing={4} sx={{ width: "80%", maxWidth: 900 }}>
          <Grid
            item
            xs={12}
            md={8}
            display={"flex"}
            flexDirection={"column"}
            sx={{
              textAlign: { xs: "center", md: "left" },
              justifyContent: "center",
            }}
          >
            <Typography
              variant="h3"
              color="white"
              fontWeight={"bold"}
              className={"fade"}
            >
              DISCOPE
            </Typography>
            <Typography
              variant="h5"
              className={"fade"}
              sx={{ color: "common.black" }}
            >
              Váš IT partner
            </Typography>
            <Box
              sx={{
                display: "flex",
                flexDirection: {
                  xs: "column",
                  md: "row",
                },
                alignItems: { xs: "center", md: "flex-start" },
                mt: 4,
              }}
            >
              {/* <Button
                onClick={() => handleScroll("contact")}
                variant={"contained"}
                endIcon={<ArrowForwardIosIcon />}
                sx={{
                  height: 45,
                  m: 2,
                  bgcolor: "rgba(0,0,0,0.9)",
                  fontWeight: "bold",
                  color: "common.white",
                  "&:hover": {
                    bgcolor: "common.white",
                    color: "common.black",
                  },
                }}
                className={"slide-bottom"}
              >
                kontakt
              </Button> */}
              <CustomButton
                label={"Kontakt"}
                onClick={() => handleScroll("contact")}
                sx={{
                  height: 45,
                  m: 2,
                  bgcolor: "rgba(0,0,0,0.9)",
                  fontWeight: "bold",
                  color: "common.white",
                  "&:hover": {
                    bgcolor: "secondary.main",
                    color: "primary.main",
                  },
                }}
              />
              <CustomButton
                label={"Stav projektu"}
                onClick={() => handleScroll("showProject")}
                sx={{
                  height: 45,
                  m: 2,
                  fontWeight: "bold",
                  color: "common.black",
                  bgcolor: "common.white",
                  "&:hover": {
                    bgcolor: "secondary.main",
                    color: "primary.main",
                  },
                }}
              />
            </Box>
          </Grid>
          <Grid item xs={0} md={4} sx={{ display: { xs: "none", md: "flex" } }}>
            <Box>
              <img src={LOGO} alt="logo" width={"100%"} style={{}} />
            </Box>
          </Grid>
        </Grid>
      </Box>
      {/* CONTENT */}
      <Paper
        elevation={10}
        className={"fade"}
        sx={{
          width: "95%",
          margin: "auto",
          bgcolor: "rgba(255,255,255,0.85)",
          position: "relative",
          top: -80,
          borderRadius: 7,
          pb: 5,
          backdropFilter: "blur(10px)",
        }}
      >
        <Stats />
        {/* JOB OFFERS */}
        <Box height={130} id={"offers"} />
        <Offers />
        {/* OUR WORK */}
        <Box height={130} id={"projects"} />
        <Projects />
        {/* REFERENCES */}
        <Box height={130} id={"references"} />
        <References />
        {/* FAQ */}
        <Box height={130} id={"faq"} />
        <FAQ />
        {/* CONTACT */}
        <Box height={130} id={"contact"} />
        <Contact />
      </Paper>
    </Box>
  );
}

const CustomButton = (props: any) => {
  return (
    <Button
      onClick={props.onClick}
      variant={"contained"}
      endIcon={<ArrowForwardIosIcon />}
      sx={props.sx}
      className={"fade"}
    >
      {props.label}
    </Button>
  );
};
