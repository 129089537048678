import { useEffect, useState } from "react";
import { AccordionDetails, AccordionSummary } from "@mui/material";
import Accordion from "@mui/material/Accordion";
import Box from "@mui/material/Box";
import Container from "@mui/material/Container";
import Grid from "@mui/material/Grid";
import Typography from "@mui/material/Typography";

import ExpandMoreIcon from "@mui/icons-material/ExpandMore";

import useFAQ from "../../../../hooks/useFAQ";

export default function FAQ() {
  const data = useFAQ();
  const [isSticky, setIsSticky] = useState(false);

  useEffect(() => {
    const handleScroll = () => {
      const element = document.querySelector("#faq");
      if (element) {
        const offset = element.getBoundingClientRect().top;
        setIsSticky(offset <= 0);
      }
    };
    window.addEventListener("scroll", handleScroll);
    return () => {
      window.removeEventListener("scroll", handleScroll);
    };
  }, []);

  return (
    <Container>
      <Grid container spacing={3}>
        <Grid item xs={12} md={4}>
          <Box className={`sticky-header move ${isSticky ? "sticky" : ""}`}>
            <Typography
              variant="h4"
              fontWeight={"bold"}
              color={"secondary.main"}
              textAlign={{ xs: "center" }}
            >
              FAQ
            </Typography>
            <Typography
              sx={{
                color: "common.black",
                width: { xs: "100%", md: "70%" },
                mx: "auto",
                mt: 2,
              }}
            >
              Zde máme výpis nejčastěji kladených otázek, pokud se zde Vaše
              otázka nenachází, neváhejte nás kontaktovat, rádi Vám odpovíme.
            </Typography>
          </Box>
        </Grid>
        <Grid item xs={12} md={8}>
          <Grid container>
            {data.map((item, index) => (
              <Accordion
                key={index}
                sx={{ width: "100%" }}
                defaultExpanded={index === 0 ? true : false}
              >
                <AccordionSummary
                  sx={{ p: 2 }}
                  expandIcon={<ExpandMoreIcon />}
                  aria-controls="panel1a-content"
                  id="panel1a-header"
                >
                  <Typography
                    sx={{ color: "secondary.main", fontWeight: "bold" }}
                  >
                    {item.question}
                  </Typography>
                </AccordionSummary>
                <AccordionDetails>{item.answer}</AccordionDetails>
              </Accordion>
            ))}
          </Grid>
        </Grid>
      </Grid>
    </Container>
  );
}
