import { useState } from "react";
import {
  Box,
  Button,
  CircularProgress,
  Container,
  Grid,
  Link,
  MenuItem,
  Select,
  TextField,
  Typography,
} from "@mui/material";

import EmailIcon from "@mui/icons-material/Email";
import LocalPhoneIcon from "@mui/icons-material/LocalPhone";
import WhatsAppIcon from "@mui/icons-material/WhatsApp";
import CheckIcon from "@mui/icons-material/Check";
import { Close } from "@mui/icons-material";

export default function Contact() {
  const [disabledButton, setDisabledButton] = useState<boolean>(false);
  const [buttonIcon, setButtonIcon] = useState<JSX.Element>(<></>);

  // CONTACT FORM STATES
  const [firstname, setFirstname] = useState<string>("");
  const handleFirstnameChange = (event: React.ChangeEvent<HTMLInputElement>) =>
    setFirstname(event.target.value);
  const [lastname, setLastname] = useState<string>("");
  const handleLastnameChange = (event: React.ChangeEvent<HTMLInputElement>) =>
    setLastname(event.target.value);
  const [email, setEmail] = useState<string>("");
  const handleEmailChange = (event: React.ChangeEvent<HTMLInputElement>) =>
    setEmail(event.target.value);
  const [phone, setPhone] = useState<string>("");
  const handlePhoneChange = (event: React.ChangeEvent<HTMLInputElement>) =>
    setPhone(
      event.target.value
        .replace(/\W/gi, "")
        .replace(/(.{3})/g, "$1 ")
        .replace(/[a-zA-Z]/g, "")
    );
  const [message, setMessage] = useState<string>("");
  const handleMessageChange = (event: React.ChangeEvent<HTMLInputElement>) =>
    setMessage(event.target.value);

  const sendMessage = () => {
    setDisabledButton(true);
    setButtonIcon(<CircularProgress size={30} />);
    if (
      verifyEmail() ||
      verifyPhone() ||
      firstname.length < 3 ||
      lastname.length < 3 ||
      message.length === 0 ||
      selectedService === "0"
    ) {
      setSelectError(true);
      setTimeout(() => {
        setButtonIcon(<Close width={30} height={30} color={"error"} />);
        setDisabledButton(true);
        setTimeout(() => {
          setButtonIcon(<></>);
          setDisabledButton(false);
        }, 2000);
      }, 1000);
    } else {
      setSelectError(false);
      setTimeout(() => {
        setButtonIcon(<CheckIcon width={30} height={30} />);
      }, 2000);
    }
  };
  const iconStyles = {
    fontSize: "2rem",
    color: "common.white",
    boxShadow: "1px 1px 60px 15px rgba(44,134,235,0.9);",
  };
  const [info] = useState<any[]>([
    {
      title: "Email",
      value: "info@discope.cz",
      icon: <EmailIcon sx={iconStyles} />,
    },
    {
      title: "Telefon",
      value: "+420 774 439 291",
      icon: <LocalPhoneIcon sx={iconStyles} />,
    },
    {
      title: "WhatsApp",
      value: "+420 774 439 291",
      icon: <WhatsAppIcon sx={iconStyles} />,
    },
  ]);

  const verifyEmail = () => {
    if (email.length === 0) return false;
    if (email.length < 3) return true;
    if (!email.includes("@")) return true;
    if (!email.includes(".")) return true;
    if (email.split("@")[0].length < 2) return true;
    if (email.split("@")[1].split(".")[0].length < 2) return true;
    if (email.split(".")[1].length < 2) return true;

    return false;
  };

  const verifyPhone = () => {
    if (phone.length === 0) return false;
    if (phone.length < 12) return true;
  };

  const [selectedService, setSelectedService] = useState<string>("0");
  const handleServiceChange = (event: any) => {
    if (event.target.value === "0") setSelectError(true);
    else setSelectError(false);
    setSelectedService(event.target.value as string);
  };
  const [selectError, setSelectError] = useState<boolean>(false);
  const [services] = useState<any[]>([
    { value: "0", label: "Výběr služby" },
    { value: "1", label: "Prezenční web" },
    { value: "2", label: "Webová aplikace" },
    { value: "3", label: "E-shop" },
    { value: "4", label: "Program" },
    { value: "5", label: "Podnikový systém" },
    { value: "5", label: "Mobilní aplikace" },
    { value: "6", label: "Jiné" },
  ]);

  return (
    <Container>
      <Box>
        <Grid container spacing={5}>
          {/* FORM */}
          <Grid item xs={12} md={6}>
            <Typography
              variant="h4"
              fontWeight={"bold"}
              color={"secondary.main"}
            >
              Kontaktujte nás
            </Typography>

            <Typography
              variant="body1"
              color={"secondary.main"}
              sx={{
                mt: 3,
                display: "flex",
                alignItems: "center",
                color: "common.black",
                flexWrap: "wrap",
              }}
            >
              <Box
                component={"span"}
                sx={{
                  width: 13,
                  height: 13,
                  bgcolor: "orange",
                  borderRadius: 50,
                  boxShadow: 10,
                  mr: 2,
                  display: "inline-block",
                }}
                className={"infinite-fade"}
              />
              Aktuálně jsme lehce vytížení, odpovídáme do
              <Typography component={"span"} fontWeight={"bold"} sx={{ mx: 1 }}>
                48
              </Typography>
              hodin.
            </Typography>

            <Typography variant="body1" color={"common.black"} sx={{ mt: 3 }}>
              Pokud máte zájem o naše služby, nebo máte nějaké dotazy, neváhejte
              nás kontaktovat. Odpovíme Vám co nejdříve.
            </Typography>

            <Grid
              container
              spacing={3}
              sx={{
                mt: 3,
              }}
            >
              <Grid item xs={12} md={6}>
                <TextField
                  fullWidth
                  error={firstname.length === 0 ? false : firstname.length < 3}
                  label="Jméno"
                  variant="outlined"
                  color="secondary"
                  value={firstname}
                  onChange={handleFirstnameChange}
                  disabled={disabledButton}
                  autoComplete={"given-name"}
                />
              </Grid>
              <Grid item xs={12} md={6}>
                <TextField
                  fullWidth
                  error={lastname.length === 0 ? false : lastname.length < 3}
                  label="Příjmení"
                  variant="outlined"
                  color="secondary"
                  value={lastname}
                  onChange={handleLastnameChange}
                  disabled={disabledButton}
                  autoComplete={"family-name"}
                />
              </Grid>
              <Grid item xs={12} md={6}>
                <TextField
                  error={verifyEmail()}
                  fullWidth
                  label="Email"
                  variant="outlined"
                  color="secondary"
                  value={email}
                  onChange={handleEmailChange}
                  type={"email"}
                  disabled={disabledButton}
                  autoComplete={"email"}
                />
              </Grid>
              <Grid item xs={12} md={6}>
                <TextField
                  error={verifyPhone()}
                  fullWidth
                  label="Telefon"
                  variant="outlined"
                  color="secondary"
                  value={phone}
                  onChange={handlePhoneChange}
                  disabled={disabledButton}
                  type={"tel"}
                  autoComplete={"phone"}
                  inputProps={{
                    maxLength: 12,
                  }}
                />
              </Grid>
              <Grid item xs={12}>
                <Select
                  fullWidth
                  error={selectError}
                  disabled={disabledButton}
                  onChange={handleServiceChange}
                  value={selectedService}
                >
                  {services.map((service, index) => (
                    <MenuItem
                      key={index}
                      value={service.value}
                      sx={{ color: "common.black" }}
                    >
                      {service.label}
                    </MenuItem>
                  ))}
                </Select>
              </Grid>
              <Grid item xs={12} md={12}>
                <TextField
                  fullWidth
                  label="Zpráva"
                  variant="outlined"
                  color="secondary"
                  value={message}
                  onChange={handleMessageChange}
                  disabled={disabledButton}
                  multiline
                  rows={4}
                />
              </Grid>
              <Grid item xs={12}>
                <Typography sx={{ color: "common.black" }}>
                  Odeslaním zprávy souhlasíte se zpracováním osobních údajů.
                </Typography>
              </Grid>
              <Grid item xs={12} md={12}>
                <Box textAlign={"center"}>
                  <Button
                    variant="contained"
                    color={"secondary"}
                    sx={{ width: 200, height: 45 }}
                    onClick={sendMessage}
                    disabled={disabledButton}
                  >
                    {disabledButton ? buttonIcon : "Odeslat"}
                  </Button>
                </Box>
              </Grid>
            </Grid>
          </Grid>
          {/* CONTACT INFO */}
          <Grid item xs={12} md={6}>
            <Box>
              <Typography
                variant="h4"
                fontWeight={"bold"}
                color={"secondary.main"}
              >
                Kontaktní informace
              </Typography>
            </Box>
            <Grid container spacing={3} sx={{ mt: 3 }}>
              {info.map((item, index) => (
                <Grid
                  item
                  xs={12}
                  md={4}
                  key={index}
                  sx={{
                    display: "flex",
                    justifyContent: "center",
                    alignItems: "center",
                    flexDirection: "column",
                  }}
                >
                  <Box
                    sx={{
                      width: 60,
                      height: 60,
                      bgcolor: "primary.main",
                      display: "flex",
                      justifyContent: "center",
                      alignItems: "center",
                      borderRadius: 50,
                      cursor: "pointer",
                    }}
                  >
                    {item.icon}
                  </Box>
                  <Link
                    variant="h6"
                    fontWeight={"bold"}
                    color={"common.black"}
                    sx={{ mt: 3, textDecoration: "none", cursor: "pointer" }}
                  >
                    {item.value}
                  </Link>
                </Grid>
              ))}
            </Grid>
            <Typography sx={{ mt: 4, color: "common.black" }}>
              V případě jakýchkoli dotazů ohledně našich služeb nebo projektů
              nás neváhejte kontaktovat. Rádi vám poskytneme všechny potřebné
              informace a odpovíme na vaše dotazy. Můžete nás kontaktovat
              prostřednictvím e-mailu, telefonu nebo pomocí kontaktního
              formuláře na našich webových stránkách. Na vaše dotazy se budeme
              snažit odpovědět co nejdříve. Těšíme se na spolupráci s vámi.
            </Typography>
          </Grid>
        </Grid>
      </Box>
    </Container>
  );
}
