import { createTheme } from "@mui/material/styles";

const breakpoints = {
  values: {
    xs: 0,
    sm: 600,
    md: 930,
    lg: 1200,
    xl: 1536,
  },
};

export const lightTheme = createTheme({
  spacing: [0, 4, 8, 16, 32, 64],
  breakpoints: breakpoints,
  palette: {
    mode: "light",
    primary: {
      main: "#2C86EB",
    },
    secondary: {
      main: "#344767",
    },
    common: {
      black: "#7B809A",
    },
  },
});

export const darkTheme = createTheme({
  breakpoints: breakpoints,
  spacing: [0, 4, 8, 16, 32, 64],
  palette: {
    mode: "dark",
    primary: {
      main: "#9c7817",
    },
    secondary: {
      main: "#f2c94c",
    },
  },
});
