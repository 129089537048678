export default function useFooter() {
  return [
    {
      title: "Odkazy",
      sublinks: [
        {
          title: "Domů",
        },
        {
          title: "Služby",
        },
        {
          title: "Projekty",
        },
        {
          title: "FAQ",
        },
      ],
    },
    {
      title: "Soubory",
      sublinks: [
        {
          title: "GDPR",
        },
        {
          title: "Zásady ochrany osobních údajů",
        },
        {
          title: "Podmínky použití",
        },
        {
          title: "Ochrana soukromí",
        },
      ],
    },
  ];
}
