import dscapp from "../images/faq/dscapp.png";
import { useNavigate } from "react-router-dom";
import { Typography } from "@mui/material";
import CustomStepper from "../components/Stepper";

export default function useFAQ() {
  const navigate = useNavigate();
  return [
    {
      question: "Jak dlouho trvá vývoj projektu?",
      answer: (
        <>
          <Typography variant="body1" sx={{ color: "common.black" }}>
            Doba vývoje projektu závisí na mnoha faktorech. Největšími faktory
            jsou složitost a rozsah aplikace. Většinou se jedná o týdny, u velmi
            komplexních aplikací se může jednat až o měsíce. Předem Vám dáme
            termín, do kterého aplikaci zhotovíme. V případě, že máte zájem o
            vývoj softwaru, kontaktujte nás a rádi vám pomůžeme s odhadem ceny.
          </Typography>
          <CustomStepper value={1} />
        </>
      ),
    },
    {
      question: "Kolik stojí vývoj projektu?",
      answer: (
        <>
          <Typography variant="body1" sx={{ color: "common.black" }}>
            Cena vývoje projektu se může lišit v závislosti na požadavcích a
            složitosti projektu. Faktory, jako jsou velikost projektu, počet
            funkcí, časová osa a požadavky na design, mohou mít vliv na celkovou
            cenu. Nejlepší způsob, jak získat přesnou cenu, je kontaktovat nás a
            poskytnout nám podrobnosti o vašem projektu. My vám poskytneme
            nabídku s přesnou cenou a termíny.
          </Typography>
          <Typography variant="body1" sx={{ color: "common.black", mt: 2 }}>
            Cenová rozmezí najdete v sekci{" "}
            <Typography
              sx={{
                display: "inline",
                fontWeight: "bold",
                color: "primary.main",
                cursor: "pointer",
              }}
              component="span"
              onClick={() => navigate("/#offers")}
            >
              služby
            </Typography>{" "}
            po kliknutí na kartu.
          </Typography>
        </>
      ),
    },
    {
      question: "Umíte upravit již existující aplikaci?",
      answer: (
        <Typography variant="body1" sx={{ color: "common.black" }}>
          Umíme upravit již existující aplikaci. Naši vývojáři mají zkušenosti s
          různými programovacími jazyky a mohou provést úpravy podle vašich
          požadavků. Můžeme například přidat nové funkce, opravit chyby nebo
          přizpůsobit design aplikace vašim potřebám. Pro více informací nás
          neváhejte kontaktovat a my vám rádi poskytneme další podrobnosti o
          našich službách úprav existujících aplikací.
        </Typography>
      ),
    },

    {
      question: "Mohu s Vámi spolupracovat i na delší dobu?",
      answer: (
        <Typography variant="body1" sx={{ color: "common.black" }}>
          Ano, můžeme spolupracovat na delší dobu. Naše služby nejsou omezeny
          jen na jednorázovou spolupráci, můžeme poskytovat podporu a údržbu pro
          vaši aplikaci po dobu, kterou potřebujete. Můžeme také poskytovat
          dlouhodobou podporu pro úpravy a další rozvoj vašeho projektu.
          Kontaktujte nás a my s Vámi rádi prodiskutujeme možnosti dlouhodobé
          spolupráce.
        </Typography>
      ),
    },
    {
      question: "Kde a jak hostovat aplikace?",
      answer: (
        <Typography variant="body1" sx={{ color: "common.black" }}>
          S naší nabídkou hostingových služeb budete mít jistotu, že vaše
          stránky budou dostupné 24/7. Nabídneme Vám různé typy hostingů od
          sdíleného až po VPS nebo dedikovaný server. Navíc poskytujeme ke
          každému projektu nasazení a prvotní spuštění zdarma. Kontaktujte nás a
          my vám rádi pomůžeme vybrat nejvhodnější řešení pro vaše potřeby.
        </Typography>
      ),
    },
    {
      question: "Jak se můžu zjistit stav projektu?",
      answer: (
        <Typography variant="body1" sx={{ color: "common.black" }}>
          Stav Vašeho projektu můžete zjistit pomocí naší aplikace nebo nás
          můžete kdykoliv kontaktovat, rádi Vám pomůžeme.
          <img src={dscapp} alt="dscapp" style={{ width: "100%" }} />
          Aplikaci můžete najít v sekci{" "}
          <Typography
            component={"span"}
            color={"secondary"}
            fontWeight={"bold"}
            sx={{ cursor: "pointer" }}
            onClick={() => navigate("/#projects")}
          >
            projekty
          </Typography>{" "}
          nebo klikněte{" "}
          <Typography
            component={"span"}
            color={"secondary"}
            fontWeight={"bold"}
            sx={{ cursor: "pointer" }}
            onClick={() => navigate("/#showProject")}
          >
            zde
          </Typography>
          .
        </Typography>
      ),
    },
  ];
}
