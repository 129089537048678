import { useState, useEffect } from "react";
import { Box, Container, Grid, Typography } from "@mui/material";
import useReferences from "../../../../hooks/useReferences";
import moment from "moment";
import Rating from "@mui/material/Rating";
import IReferences from "../../../../interface/IReferences";

import AccessTimeIcon from "@mui/icons-material/AccessTime";
import StarRoundedIcon from "@mui/icons-material/StarRounded";

export default function References() {
  const data: IReferences[] = useReferences();
  const [references, setReferences] = useState<IReferences[]>([]);
  const [index, setIndex] = useState<number>(0);
  const [fade, setFade] = useState(false);

  const nextSlide = () => {
    if (index === data.length - 3) {
      setIndex(0);
    } else {
      setIndex(index + 1);
    }
  };

  useEffect(() => {
    if (data.length > 3) {
      const _interval = setInterval(() => {
        nextSlide();
      }, 5000);
      return () => clearInterval(_interval);
    }
  });

  useEffect(() => {
    setFade(true);
    setTimeout(() => {
      setFade(false);
    }, 1000);
    setReferences(data.slice(index, index + 3));
  }, [index]);

  return (
    <Container>
      <Box>
        <Typography
          variant="h4"
          fontWeight={"bold"}
          color={"secondary.main"}
          textAlign={"center"}
        >
          Reference
        </Typography>
        <Grid container spacing={3} sx={{ mt: 3 }}>
          {references.map((item: IReferences, index: number) => (
            <Grid item xs={12} md={4} key={index} my={3}>
              <Box
                sx={{
                  px: 4,
                  borderRadius: 7,
                  py: 4,
                  background:
                    index === 1
                      ? "linear-gradient(13deg, rgba(44,134,235,1) 43%, rgba(36,73,135,1) 99%)"
                      : "",
                }}
                className={index === 2 && fade ? "fade" : ""}
              >
                <Typography
                  color={index === 1 ? "common.white" : "secondary"}
                  fontWeight={"bold"}
                  fontSize={"1.2rem"}
                >
                  {item.name}
                </Typography>
                <Box sx={{ display: "flex", alignItems: "center" }}>
                  <AccessTimeIcon
                    sx={{
                      color: index === 1 ? "common.white" : "common.black",
                    }}
                  />
                  <Typography
                    color={index === 1 ? "common.white" : "common.black"}
                    fontSize={"1rem"}
                    ml={2}
                  >
                    {moment(item.date).fromNow()}
                  </Typography>
                </Box>
                <Typography
                  color={index === 1 ? "common.white" : "common.black"}
                  mt={4}
                  height={100}
                >
                  <Typography
                    component={"span"}
                    sx={{
                      color: index === 1 ? "common.white" : "common.black",
                      fontWeight: "bold",
                      fontSize: "1rem",
                    }}
                  >
                    „
                  </Typography>
                  {item.description}
                  <Typography
                    component={"span"}
                    sx={{
                      color: index === 1 ? "common.white" : "common.black",
                      fontWeight: "bold",
                      fontSize: "1rem",
                    }}
                  >
                    “
                  </Typography>
                </Typography>
                <Typography
                  sx={{
                    color: index === 1 ? "common.white" : "common.black",
                    my: 3,
                    fontWeight: "bold",
                  }}
                >
                  {item.type}
                </Typography>
                <Rating
                  name="read-only"
                  value={item.stars}
                  readOnly
                  icon={
                    <StarRoundedIcon
                      sx={{
                        fontSize: "1.6rem",
                        color: index === 1 ? "common.white" : "secondary.main",
                      }}
                    />
                  }
                  emptyIcon={<></>}
                />
              </Box>
            </Grid>
          ))}
        </Grid>
      </Box>
    </Container>
  );
}
