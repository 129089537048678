import { StepIconProps } from "@mui/material/StepIcon";
import React from "react";

import { styled } from "@mui/material/styles";
import Stack from "@mui/material/Stack";
import Stepper from "@mui/material/Stepper";
import Step from "@mui/material/Step";
import StepLabel from "@mui/material/StepLabel";

import MarkChatReadIcon from "@mui/icons-material/MarkChatRead";
import DisplaySettingsIcon from "@mui/icons-material/DisplaySettings";
import BugReportIcon from "@mui/icons-material/BugReport";
import PresentToAllIcon from "@mui/icons-material/PresentToAll";
import StepConnector, {
  stepConnectorClasses,
} from "@mui/material/StepConnector";

const ColorlibConnector = styled(StepConnector)(({ theme }) => ({
  [`&.${stepConnectorClasses.alternativeLabel}`]: {
    top: 22,
  },
  [`&.${stepConnectorClasses.active}`]: {
    [`& .${stepConnectorClasses.line}`]: {
      backgroundImage:
        "linear-gradient(13deg, rgba(44,134,235,1) 43%, rgba(36,73,135,1) 99%)",
    },
  },
  [`&.${stepConnectorClasses.completed}`]: {
    [`& .${stepConnectorClasses.line}`]: {
      backgroundImage:
        "linear-gradient(13deg, rgba(44,134,235,1) 43%, rgba(36,73,135,1) 99%)",
    },
  },
  [`& .${stepConnectorClasses.line}`]: {
    height: 3,
    border: 0,
    backgroundColor:
      theme.palette.mode === "dark" ? theme.palette.grey[800] : "#eaeaf0",
    borderRadius: 1,
  },
}));

const ColorlibStepIconRoot = styled("div")<{
  ownerState: { completed?: boolean; active?: boolean };
}>(({ theme, ownerState }) => ({
  backgroundColor:
    theme.palette.mode === "dark" ? theme.palette.grey[700] : "#ccc",
  zIndex: 1,
  color: "#fff",
  width: 50,
  height: 50,
  display: "flex",
  borderRadius: "50%",
  justifyContent: "center",
  alignItems: "center",
  ...(ownerState.active && {
    backgroundImage:
      "linear-gradient(13deg, rgba(44,134,235,1) 43%, rgba(36,73,135,1) 99%)",
    boxShadow: "0 4px 10px 0 rgba(0,0,0,.25)",
  }),
  ...(ownerState.completed && {
    backgroundImage:
      "linear-gradient(13deg, rgba(44,134,235,1) 43%, rgba(36,73,135,1) 99%)",
  }),
}));

function ColorlibStepIcon(props: StepIconProps) {
  const { active, completed, className } = props;

  const icons: { [index: string]: React.ReactElement } = {
    1: <MarkChatReadIcon />,
    2: <DisplaySettingsIcon />,
    3: <BugReportIcon />,
    4: <PresentToAllIcon />,
  };

  return (
    <ColorlibStepIconRoot
      ownerState={{ completed, active }}
      className={className}
    >
      {icons[String(props.icon)]}
    </ColorlibStepIconRoot>
  );
}

const steps = ["Domluva", "Návrh", "Testování", "Odevzdání"];

interface IProps {
  value: number;
}

export default function CustomStepper({ value }: IProps) {
  return (
    <Stack sx={{ width: "100%", mt: 4 }} spacing={4}>
      <Stepper
        alternativeLabel
        activeStep={value}
        connector={<ColorlibConnector />}
      >
        {steps.map((label) => (
          <Step key={label}>
            <StepLabel StepIconComponent={ColorlibStepIcon}>{label}</StepLabel>
          </Step>
        ))}
      </Stepper>
    </Stack>
  );
}
