import { Box, Grid, Typography } from "@mui/material";
import Container from "@mui/material/Container";
import CountUp from "react-countup";
import useStats from "../../../../hooks/useStats";

export default function Stats() {
  const data = useStats();

  return (
    <Container
      sx={{ width: "60%", margin: "auto", pt: 4, minWidth: { md: 900 } }}
    >
      {/* STATIC INFO */}
      <Grid container display={"flex"} justifyContent={"center"}>
        {data.map((item: any, index: any) => (
          <Grid
            key={index}
            item
            xs={12}
            md={4}
            my={3}
            borderRight={{
              xs: 0,
              md: index === 1 ? "1px solid rgba(52,71,103,0.2)" : 0,
            }}
            borderLeft={{
              xs: 0,
              md: index === 1 ? "1px solid rgba(52,71,103,0.2)" : 0,
            }}
            sx={{
              animation: item.animation
                ? { xs: "none", md: item.animation }
                : "",
            }}
            display={"flex"}
            justifyContent={{
              xs: "center",
              md:
                index === 0
                  ? "flex-end"
                  : index === 1
                  ? "center"
                  : "flex-start",
            }}
          >
            <Box sx={{ minWidth: 300 }}>
              <Typography
                fontSize={"3rem"}
                fontWeight={"bold"}
                color="primary.main"
                textAlign={"center"}
              >
                <CountUp end={item.value} duration={2} />
                {item.sufix && `${item.sufix}`}
              </Typography>
              <Box className={"fade"}>
                <Typography
                  variant="h6"
                  mt={2}
                  mb={3}
                  fontWeight={"bold"}
                  textAlign={"center"}
                  color={"secondary.main"}
                >
                  {item.title}
                </Typography>
                <Typography
                  variant="body1"
                  color="common.black"
                  textAlign={"center"}
                  mx={"auto"}
                  width={250}
                >
                  {item.desription}
                </Typography>
              </Box>
            </Box>
          </Grid>
        ))}
      </Grid>
    </Container>
  );
}
