import React from "react";
import ReactDOM from "react-dom/client";
import reportWebVitals from "./reportWebVitals";
import { BrowserRouter as Router, Routes, Route } from "react-router-dom";
import "./css/index.css";
import {
  Box,
  CssBaseline,
  StyledEngineProvider,
  ThemeProvider,
} from "@mui/material";
import { lightTheme } from "./theme/themeValues";

import Navbar from "./components/Navbar";
import Home from "./screens/Home";
import Footer from "./components/Footer";
import ErrorHandler from "./screens/ErrorHandler";

const root = ReactDOM.createRoot(
  document.getElementById("root") as HTMLElement
);

// welcome message
console.log(
  "Co tu hledáš? 🤔\nU nás chyby nenajdeš, pečlivě vše testujeme a opravujeme. 🐛\nPokud nám chceš něco sdělit, nebo máš nápad na vylepšení, tak nám napiš!"
);

root.render(
  <React.StrictMode>
    <StyledEngineProvider injectFirst>
      <ThemeProvider theme={lightTheme}>
        <CssBaseline />
        <Router>
          <Navbar />
          <Box sx={{ bgcolor: "#EEF0F3" }}>
            <Routes>
              <Route path="/" element={<Home />} />
              <Route path="/project/:uuid" element={<Home />} />
              <Route path="*" element={<ErrorHandler />} />
            </Routes>
            <Footer />
          </Box>
        </Router>
      </ThemeProvider>
    </StyledEngineProvider>
  </React.StrictMode>
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
