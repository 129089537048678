export default function useReferences() {
  return [
    {
      name: "Zhanna",
      date: "2023-01-08",
      description:
        "Jsem naprosto spokojená s webem. Je přehledný, zákazníci najdou přesně co potřebujou. Navíc mi pomohl zvýšit prodejnost.",
      stars: 5,
      type: "Webové stránky a aplikace",
    },
    {
      name: "Slavomír",
      date: "2022-12-01",
      description:
        "Stránky jsou snadno ovladatelný, což mi ušetřilo spoustu času při práci s nemovitostmi. ",
      stars: 4,
      type: "Webové stránky a aplikace",
    },
    {
      name: "Roman",
      date: "2021-10-01",
      description:
        "Aplikaci používáme už přes rok a jsme spokojeni. Po domluvě si převzali i webové stránky, o které se momentálně starají.",
      stars: 5,
      type: "Webové stránky a program",
    },
  ];
}
