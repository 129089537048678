import { Close } from "@mui/icons-material";
import {
  Box,
  CircularProgress,
  Grid,
  TextField,
  Typography,
} from "@mui/material";
import { useState, useEffect } from "react";
import useCustomerProjects from "../../hooks/useCustomerProjects";
import ICustomerProject from "../../interface/ICustomerProject";
import ProjectModal from "./ProjectModal";
import { useParams } from "react-router-dom";
import moment from "moment";

export default function ProjectFinder() {
  const { uuid } = useParams<{ uuid: string }>();
  const [errorCount, setErrroCount] = useState<number>(0);

  const [search, setSearch] = useState<string>("");
  const handleSearchChange = (e: React.ChangeEvent<HTMLInputElement>) =>
    setSearch(e.target.value);
  const [loadingStatus, setLoadingStatus] = useState<number>(0);

  const [showModal, setShowModal] = useState<boolean>(false);
  const [modalData, setModalData] = useState<ICustomerProject>();

  const SearchProject = () => {
    const customerProjects: ICustomerProject[] = useCustomerProjects();
    const uuidFormat =
      /^[0-9a-f]{8}-[0-9a-f]{4}-[1-5][0-9a-f]{3}-[89ab][0-9a-f]{3}-[0-9a-f]{12}$/i;
    if (uuidFormat.test(search)) {
      setLoadingStatus(1);
      // FIND PROJECT
      const project = customerProjects.find(
        (project) => project.uuid === search
      );

      if (project) {
        setTimeout(() => {
          setShowModal(true);
          setModalData(project);
          setSearch("");
        }, 2000);
      } else {
        setTimeout(() => {
          setLoadingStatus(2);
          setErrroCount((prev) => prev + 1);
        }, 1500);
      }
      setTimeout(() => {
        if (errorCount === 5) return;
        setLoadingStatus(0);
      }, 3000);
    }
  };

  useEffect(() => {
    if (errorCount === 5) {
      localStorage.setItem("searchBan", moment.now().toString());
      console.log(moment.now().toString());
      setLoadingStatus(2);
    }
  }, [errorCount]);

  const verifySearch = () => {
    if (search.length === 0) return false;
    if (search.length === 36) {
      const uuidFormat =
        /^[0-9a-f]{8}-[0-9a-f]{4}-[1-5][0-9a-f]{3}-[89ab][0-9a-f]{3}-[0-9a-f]{12}$/i;
      if (uuidFormat.test(search)) {
        return false;
      }
    }
    return true;
  };

  useEffect(() => {
    if (search.length === 36) {
      SearchProject();
    }
  }, [search]);

  useEffect(() => {
    if (uuid) {
      setTimeout(() => {
        setSearch(uuid);
      }, 500);
    }
  }, [uuid]);
  return (
    <>
      <ProjectModal
        showModal={showModal}
        closeModal={() => setShowModal(false)}
        modalData={modalData}
      />
      <Box id={"showProject"} height={120} />
      <Grid item xs={12} sx={{ mt: { xs: 4, md: 3 }, pb: 5 }}>
        <Typography
          variant="h6"
          sx={{
            color: "secondary.main",
            fontWeight: "bold",
            fontSize: "1.7rem",
            textAlign: "center",
          }}
        >
          Stav projektu
        </Typography>
        <Typography
          color={"common.black"}
          sx={{
            width: "90%",
            maxWidth: 500,
            minWidth: 300,
            margin: "auto",
            my: 2,
            mt: 4,
          }}
        >
          Máte u nás projekt? Pomocí naší aplikace máte veškeré potřebné
          informace zde. Zadejte ID projektu, které jsme Vám zaslali na email.
        </Typography>
        <Box
          sx={{
            width: "90%",
            maxWidth: 500,
            minWidth: 300,
            margin: "auto",
          }}
        >
          <TextField
            fullWidth
            value={search}
            disabled={loadingStatus !== 0}
            error={verifySearch()}
            onChange={handleSearchChange}
            placeholder={"Zadejte ID projektu"}
            inputProps={{
              maxLength: 36,
            }}
            sx={{
              height: 40,
            }}
          />
          <Typography
            sx={{
              color: "common.black",
              position: "relative",
              top: "20px",
              fontWeight: "bold",
              cursor: "pointer",
              width: "fit-content",
            }}
            onClick={() => setSearch("c2ac3d48-ae5e-4ce0-8e14-48089d67a51e")}
          >
            Zobrazit stav projektu
          </Typography>
          <Box sx={{ height: 30 }}>
            <Box
              sx={{
                position: "relative",
                opacity: loadingStatus !== 0 ? 1 : 0,
                width: "100%",
                display: "flex",
                justifyContent: "center",
                top: -55,
              }}
            >
              {loadingStatus === 1 ? (
                <CircularProgress />
              ) : loadingStatus === 2 ? (
                <Close sx={{ width: 40, height: 40, color: "red" }} />
              ) : (
                <></>
              )}
            </Box>
          </Box>
        </Box>
      </Grid>
    </>
  );
}
