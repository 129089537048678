import { useState, useEffect } from "react";
import { Box, Container, Divider, Grid, Typography } from "@mui/material";

import {
  IOffer,
  IOfferBenefits,
  IOfferItems,
} from "../../../../interface/IOffer";
import ICustomerProject from "../../../../interface/ICustomerProject";

import useOffers from "../../../../hooks/useOffers";
import OfferCard from "../../../../components/OfferCard";
import useCustomerProjects from "../../../../hooks/useCustomerProjects";

import OfferModal from "../../../../components/Project/ProjectModal";

export default function Offers() {
  const data: IOffer = useOffers();
  const customerProjects: ICustomerProject[] = useCustomerProjects();
  const [isSticky, setIsSticky] = useState(false);

  const [search, setSearch] = useState<string>("");

  const [showModal, setShowModal] = useState<boolean>(false);
  const [modalData, setModalData] = useState<ICustomerProject>();

  useEffect(() => {
    const handleScroll = () => {
      const element = document.querySelector("#offers");
      if (element) {
        const offset = element.getBoundingClientRect().top;
        setIsSticky(offset <= 0);
      }
    };
    window.addEventListener("scroll", handleScroll);
    return () => {
      window.removeEventListener("scroll", handleScroll);
    };
  }, []);

  const searchProject = () => {
    const uuidFormat =
      /^[0-9a-f]{8}-[0-9a-f]{4}-[1-5][0-9a-f]{3}-[89ab][0-9a-f]{3}-[0-9a-f]{12}$/i;
    if (uuidFormat.test(search)) {
      // FIND PROJECT
      const project = customerProjects.find(
        (project) => project.uuid === search
      );

      if (project) {
        setTimeout(() => {
          setShowModal(true);
          setModalData(project);
          setSearch("");
        }, 2000);
      }
    }
  };

  useEffect(() => {
    if (search.length === 36) {
      searchProject();
    }
  }, [search]);

  return (
    <Container>
      <OfferModal
        modalData={modalData}
        showModal={showModal}
        closeModal={() => setShowModal(false)}
      />
      <Grid container spacing={3}>
        <Grid item xs={12} md={4}>
          <Box className={`sticky-header move ${isSticky ? "sticky" : ""}`}>
            <Typography
              variant="h4"
              fontWeight={"bold"}
              color={"secondary.main"}
              textAlign={{ xs: "center" }}
            >
              Nabízíme
            </Typography>
            <Typography
              sx={{
                color: "common.black",
                width: { xs: "100%", md: "70%" },
                mx: "auto",
                mt: 2,
              }}
            >
              Nabízíme širokou škálu cenově dostupných softwarových řešení pro
              různé potřeby a obory. Náš tým odborníků se specializuje na vývoj
              custom aplikací, webových stránek a e-shopů, intranetových systémů
              a mobilních aplikací.
              <Typography
                component={"span"}
                color={"common.black"}
                sx={{ display: "block", mt: 3 }}
              >
                Pro zobrazení cen klikněte na kartu.
              </Typography>
            </Typography>
          </Box>
        </Grid>
        <Grid item xs={12} md={8}>
          <Grid item xs={12}>
            <Divider>
              <Typography
                variant="h6"
                sx={{
                  color: "secondary.main",
                  fontWeight: "bold",
                  fontSize: "1.7rem",
                  textAlign: "center",
                }}
              >
                Produkty
              </Typography>
            </Divider>
          </Grid>
          <Grid container spacing={4} mt={1}>
            {data.offers.map((item: IOfferItems, index) => (
              <OfferCard data={item} key={index} />
            ))}
            <Grid item xs={12}>
              <Divider>
                <Typography
                  variant="h6"
                  sx={{
                    color: "secondary.main",
                    fontWeight: "bold",
                    fontSize: "1.7rem",
                    textAlign: "center",
                  }}
                >
                  Služby
                </Typography>
              </Divider>
            </Grid>
            {data.services.map((item: IOfferItems, index) => (
              <OfferCard data={item} key={index} />
            ))}
          </Grid>

          <Grid
            item
            xs={12}
            md={8}
            sx={{
              mt: 5,
              mx: "auto",
            }}
          >
            <Typography
              variant="h6"
              sx={{
                color: "secondary.main",
                fontWeight: "bold",
                fontSize: "1.7rem",
                textAlign: "center",
              }}
            >
              Ke každému projektu dostanete
            </Typography>
            <Grid
              container
              spacing={3}
              sx={{ mt: 4, width: "80%", mx: "auto" }}
            >
              {data.benefits.map((item: IOfferBenefits, index) => (
                <Grid item xs={12} md={12} lg={6} key={index} display={"flex"}>
                  <Box
                    display={"flex"}
                    width={"fit-content"}
                    alignItems={"center"}
                  >
                    {item.icon}
                    <Typography
                      sx={{
                        color: "common.black",
                        textTransform: "uppercase",
                        fontWeight: "bold",
                        ml: 2,
                      }}
                    >
                      {item.title}
                    </Typography>
                  </Box>
                </Grid>
              ))}
            </Grid>
          </Grid>
        </Grid>
      </Grid>
    </Container>
  );
}
